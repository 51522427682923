a {
    text-decoration: none;
    transition:  all 0.3s ease-out;

    &:link {
        color: @link-color;
    }

    &:visited {
        color: @link-color;
    }

    &:hover {
        color: @text-color;
    }

    &:active {
        color: @text-color;
    }
}

html {
    margin: 0;
    padding: 0;
}

body {
    color: @text-color;
    background-color: @bg-color;
    font-family: @font-family-sans-serif;
    margin: 0;
    padding: 0;
    font-size: @font-size-base;
}


h1 { font-size: @font-size-h1; }
h2 { font-size: @font-size-h2; }
h3 { font-size: @font-size-h3; }
h4 { font-size: @font-size-h4; }
h5 { font-size: @font-size-h5; }
h6 { font-size: @font-size-h6; }

.container {
    background-color: white;
    box-shadow: 3px 3px 9px rgba(0, 0, 0, 0.2);
}

strong {
    font-weight: bold;
    color: @bright-color;
}

.vertically-middle(@pos: absolute) {
    position: @pos;
    top: 50%;
    transform: translate(0, -50%);
}

.horizontally-middle(@pos: relative) {
    position: @pos;
    margin-left: auto;
    margin-right: auto;
}

.lte-ie8 .lte-ie8-comment-wrapper {
    color: white;
    background-color: @bright-color;
    width: 100%;
    height: 100%;
    position: fixed;
    padding: 0;
    margin: 0;
}

.lte-ie8 p, .lte-ie8 .container, .lte-ie8 .links {
    display: none;
}

.lte-ie8 .lte-ie8-comment-wrapper p {
    text-align: center;
    font-size: @font-size-huge;
    margin-top: 25%;
    display: block;
}

ul, ol, dl {
    line-height: @line-height-base;
}