//== Colors
@text-color: @dark-color;
@bg-color: @light-color;
@light-shadow: 3px 3px 9px rgba(0, 0, 0, 0.2);


//== Typography
//Font, line-height, and color for body text, headings, and more.
@font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-monospace:  Monaco, Consolas, "Courier New", monospace;
@font-family-serif:      Georgia, "Times New Roman", Times, serif;

@font-size-base:  16px;
@font-size-huge: @font-size-base * 2;
@font-size-large: @font-size-base * 1.25;
@font-size-medium: @font-size-base * 1.125;
@font-size-small: @font-size-base * 0.875;
@font-size-h1:    @font-size-base * 4.5;
@font-size-h2:    @font-size-base * 1.3;
@font-size-h3:    @font-size-base * 1.25;
@font-size-h4:    @font-size-base;
@font-size-h5:    @font-size-base;
@font-size-h6:    @font-size-base * 0.875; // 14px


// Unit-less `line-height` for use in components like buttons.
@line-height-base: 1.428571429; // 20/14
// Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed: floor((@font-size-base * @line-height-base)); // ~20px
// By default, this inherits from the `<body>`.
@headings-font-family: inherit;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: inherit;

//== Links
// -------------------------
@link-color: @bright-color;
@link-hover-color: @bright-contrast-color;
@link-active-color: darken(@link-hover-color, 25%);

//== Icons
@fa-font-path:       "static/fonts";
@fa-font-size-base:   14px;
@fa-css-prefix:       fa;
@fa-version:          "4.3.0";
@fa-font-size-base:   14px;


//== Media queries breakpoints
// --------------------------------------------------
// Small screen / tablet
@screen-sm: 768px;
@screen-sm-min: @screen-sm;
@screen-tablet: @screen-sm-min;
// Medium screen / desktop
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-desktop: @screen-md-min;
// Large screen / wide desktop
@screen-lg: 1200px;
@screen-lg-min: @screen-lg;
@screen-lg-desktop: @screen-lg-min;
// So media queries don't overlap when required, provide a maximum
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);

//= Grid system
// --------------------------------------------------
// Number of columns in the grid system
@grid-columns: 12;
// Padding, to be divided by two and applied to the left and right of all columns
@grid-gutter-width: 30px;
@grid-float-breakpoint: @screen-md-min;
@grid-columns-breakpoint: 4;

//= Container sizes
// Small screen / tablet
@container-tablet: ((720px + @grid-gutter-width));
@container-sm: @container-tablet;
// Medium screen / desktop
@container-desktop: ((940px + @grid-gutter-width));
@container-md: @container-desktop;
// Large screen / wide desktop
@container-large-desktop: ((1000px + @grid-gutter-width));
@container-lg: @container-large-desktop;

//== Components
//
// Define common padding and border radius sizes and more.
// Values based on 14px text and 1.428 line-height (~20px to start).
@padding-base-vertical: 6px;
@padding-base-horizontal: 12px;

@padding-large-vertical: 10px;
@padding-large-horizontal: 16px;

@padding-huge-vertical: 14px;
@padding-huge-horizontal: 20px;

@padding-small-vertical: 5px;
@padding-small-horizontal: 10px;

@padding-xs-vertical: 1px;
@padding-xs-horizontal: 5px;

@padding-container-vertical: 30px;
@padding-container-horizontal: @padding-container-vertical + (@grid-gutter-width / 2);


@line-height-large: 1.33;
@line-height-small: 1.5;

@border-radius-base: 4px;
@border-radius-large: 6px;
@border-radius-small: 3px;
@border-radius-huge: 10px;
@border-radius-circle: 100%;

@border-divide: 2px solid @text-color;

//== Section
@sec-icon-size: 32px;
@sec-icon-padding: 6px;


//== Timeline
@timeline-icon-overlap: (@sec-icon-size + @sec-icon-padding) / 2 + 10px;
@timeline-icon-radius: (@sec-icon-size) / 2 + @sec-icon-padding;
@timeline-item-size: 26px;
@timeline-item-padding: 6px;
@timeline-content-padding: 16px;
@timeline-end-size: 14px;
@timeline-line-width: 4px;

//== Progress
@progress-fill-color: @bright-color;
@progress-empty-color: @text-color;
@progress-label-color: white;

//== Sidebar
