@media print {
  .container {
    width: 1000px;
    margin-top: 0;
    box-shadow: none;
  }

  body {
    background: transparent;
  }

  aside, footer {
    display: none;
  }

}