.education-name {
    font-size: @font-size-huge;
    font-weight: bold;
    margin: @padding-large-vertical 0 @padding-small-vertical 0;
}

.education-major, .education-honor {
    list-style-type: none;
    line-height: @line-height-base;
    margin: 0;
    padding: 0;
}

.set-section-icon(education, @fa-var-book);
.set-timeline-icon(education-undergraduate, @fa-var-bookmark);
.set-li-icon(education-major, @fa-var-graduation-cap);
.set-li-icon(education-honor, @fa-var-trophy);
