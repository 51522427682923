.progress {
    border-radius: @border-radius-base;
    background-color: @progress-empty-color;
    overflow: hidden;
}

.progress-bar {
    background-color: @progress-fill-color;
    text-align: right;
    padding-right: @padding-small-horizontal;
}

.progress-label {
    color: @progress-label-color;
    font-size: inherit;
}
