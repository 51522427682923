.skills-item-description {
    padding-left: 0;
}

.skills-item {
    margin: @padding-large-vertical 0;
}

.skills-item-description {
    margin: 0;
}

.skills-item-summary {
    font-size: @font-size-medium;
    margin: @padding-large-vertical 0 @padding-small-vertical 0;
    font-weight: bold;
}

.skills-miscellaneous {
    margin-top: @padding-large-vertical;
    padding: 0;
}

.skills-miscellaneous .skills-item-description {
    list-style-type: none;
}

.set-section-icon(skills, @fa-var-wrench);
.set-timeline-icon(skills-frontend, @fa-var-puzzle-piece);
.set-timeline-icon(skills-backend, @fa-var-cloud);
.set-timeline-icon(skills-miscellaneous, @fa-var-gears);
.set-li-icon(skills-item-description, @fa-var-star);
